import {Person} from "./person.model";
import {Company} from "./company.model";
import {Title} from "../enums/title.enum";
import {ContactState} from "../enums/contact-state.enum";
import {ContactInfluenceDegree} from "../enums/contact-influence-degree.enum";
import {ContactTypeEnum} from "../enums/contact-type-enum";
import {ContactIndustry} from "../enums/contact-industry.enum";
import {GdprConsentModel} from "./gdpr/gdpr-consent.model";

export class Contact {
  id: number;
  label: string;
  firstName: string;
  lastName: string;
  title: Title;
  companyName: string;
  similarity: number;
  completion: number;
  jobTitle: string;
  status: ContactState;
  industries: ContactIndustry[];
  otherIndustry: string;
  locationCity: string;
  locationCountry: string;
  locationCountryCode: string;
  influenceDegree: ContactInfluenceDegree;
  contactType: ContactTypeEnum;
  contactTypeInfo: string;
  owner: Person;
  company: Company;
  email: string;
  gdprConsent: GdprConsentModel | null;

  constructor(id: number, label: string, firstName: string, lastName: string,
              title: Title, companyName: string, similarity: number,
              completion: number, jobTitle: string, status: ContactState,
              industries: ContactIndustry[], otherIndustry: string, locationCity: string, locationCountry: string,
              locationCountryCode: string,influenceDegree: ContactInfluenceDegree, contactType: ContactTypeEnum,
              contactTypeInfo: string, owner: Person,
              company: Company, email: string, gdprConsent: GdprConsentModel | null) {
    this.id = id;
    this.label = label;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.companyName = companyName;
    this.similarity = similarity;
    this.completion = completion;
    this.jobTitle = jobTitle;
    this.status = status;
    this.industries = industries;
    this.otherIndustry = otherIndustry;
    this.locationCity = locationCity;
    this.locationCountry = locationCountry;
    this.locationCountryCode = locationCountryCode;
    this.influenceDegree = influenceDegree;
    this.contactType = contactType;
    this.contactTypeInfo = contactTypeInfo;
    this.owner = owner;
    this.company = company ? new Company(
      company.id, company.name,
      company.legalForm, company.group,
      company.industries, company.address,
      company.demasyOrganisationId, company.demasyOrganisationName,
      company.demasyOrganisationStatus
    ) : null;
    this.email = email;
    this.gdprConsent = gdprConsent ? new GdprConsentModel(
      gdprConsent.status, gdprConsent.newsConsent,
      gdprConsent.eventsConsent, gdprConsent.dotDigitalConsent,
      gdprConsent.createdAt, gdprConsent.modifiedAt
    ): null;
  }
}
