import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {NgClass, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import {OverlayPanel, OverlayPanelModule} from "primeng/overlaypanel";
import {PaginatorModule} from "primeng/paginator";
import {PrimeTemplate} from "primeng/api";
import {AvatarUser} from "../models/avatar-user.model";
import {UserRole} from "../../../enums/user-role.enum";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Subscription, tap} from "rxjs";
import {RandomAvatarColorSchemeService} from "../services/random-avatar-color-scheme.service";
import {TooltipModule} from "primeng/tooltip";
import {InlineSVGModule} from "ng-inline-svg-2";

@Component({
  selector: 'crm-avatar-singular',
  standalone: true,
  imports: [
    NgOptimizedImage,
    OverlayPanelModule,
    PaginatorModule,
    PrimeTemplate,
    TitleCasePipe,
    NgClass,
    TooltipModule,
    InlineSVGModule,
    ReactiveFormsModule
  ],
  templateUrl: './avatar-singular.component.html',
  styleUrl: './avatar-singular.component.scss'
})
export class AvatarSingularComponent implements OnChanges, AfterViewInit, OnDestroy {
  /** The overlay responsible for selecting an avatar, contains the list of avatars to choose from. */
  @ViewChild('selectOverlay') selectOverlay: OverlayPanel;
  /** Determines if the avatar is static, or interactive (clickable), defaults to false. */
  @Input({required: false}) static: boolean = false;
  /** The selected avatar user. */
  @Input({required: false}) selectedUser: AvatarUser;
  /** List of all the avatar users available to be selected. */
  @Input({required: false}) usersList: AvatarUser[] = [];
  /** Determines if the avatar should have a full name tooltip, defaults to true. */
  @Input({required: false}) showNameTooltip: boolean = true;
  /** Event emitted when the selected user changes. */
  @Output() selectionChange: EventEmitter<AvatarUser> = new EventEmitter();
  /** Event emitted when the search value changes. */
  @Output() searchValueChange: EventEmitter<string> = new EventEmitter();
  /** Indicates if the avatar should have a checkmark. */
  check: boolean;
  fullName: string;

  /** Color scheme for the avatar. */
  avatarColorScheme: string;
  /** Form control for handling search input. */
  searchFormControl = new FormControl('');
  /** List of subscriptions to be cleaned up on component destruction. */
  subscriptions: Subscription[] = [];

  constructor(public randomAvatarColorSchemeService: RandomAvatarColorSchemeService) {
  }

  /**
   * Updates the avatar color scheme, full name and the check state if the selected person changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedUser']) {
      if (this.selectedUser) {
        this.fullName = this.selectedUser.person.firstName + ' ' + this.selectedUser.person.lastName;
        this.check = this.selectedUser.checked;
        this.avatarColorScheme = this.getColorScheme(this.fullName);
      }
    }
  }

  /** This is responsible for debouncing the search field */
  ngAfterViewInit() {
    this.subscriptions.push(
      this.searchFormControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap((value) => {
            this.searchValueChange.emit(value);
          })
        ).subscribe()
    );
  }

  /**
   * Handles the avatar click event to toggle an overlay if the avatar is not static.
   * @param overlay - The overlay component to be toggled.
   * @param event - The event that triggered the click.
   */
  onAvatarClick(overlay: any, event: any) {
    if (!this.static) {
      overlay.toggle(event);
    }
  }

  /**
   * Select or unselect a user and emits the new selection.
   */
  toggleUser(personId: number, selectOverlay: OverlayPanel) {
    const newSelectedUser = this.selectedUser?.person.id === personId ? null
      : this.usersList.find(person => person.person.id === personId);
    this.selectionChange.emit(newSelectedUser);
    selectOverlay.hide();
  }

  /**
   * Gets the color scheme for an avatar based on the user's full name.
   */
  getColorScheme(fullName: string) {
    return this.randomAvatarColorSchemeService.getAvatarClassByUserName(fullName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  protected readonly UserRole = UserRole;
}
