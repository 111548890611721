import {Email} from "../interfaces/email.interface";
import {PhoneNumber} from "../interfaces/phone-number.interface";
import {Address} from "./address.model";
import {ContactDetailModel} from "./contact-detail.model";
import {Person} from "./person.model";
import {ContactList} from "./contact-list.model";
import {SocialMedia} from "../interfaces/social-media.interface";
import {Contact} from "./contact.model";
import {Participant} from "./participant.modal";

export class UpdateContactDetailsModel {
  contact: Contact;
  participants: Participant[];
  secondaryOwners: Person[];
  emails: Email[];
  phoneNumbers: PhoneNumber[];
  socialMedias: SocialMedia[];
  addresses: Address[];
  contactLists: ContactList[];
  groupId: number;

  init = (contactDetail: ContactDetailModel) => {
    this.contact = new Contact(
      contactDetail.contact.id,
      contactDetail.contact.label,
      contactDetail.contact.firstName,
      contactDetail.contact.lastName,
      contactDetail.contact.title,
      contactDetail.contact.companyName,
      null, null,
      contactDetail.contact.jobTitle,
      contactDetail.contact.status,
      contactDetail.contact.industries,
      contactDetail.contact.otherIndustry,
      contactDetail.contact.locationCity,
      contactDetail.contact.locationCountry,
      contactDetail.contact.locationCountryCode,
      contactDetail.contact.influenceDegree,
      contactDetail.contact.contactType,
      contactDetail.contact.contactTypeInfo,
      contactDetail.contact.owner,
      contactDetail.contact.company,
      contactDetail.contact.email,
      contactDetail.contact.gdprConsent
    );
    this.participants = contactDetail.participants;
    this.emails = contactDetail.emails;
    this.phoneNumbers = contactDetail.phoneNumbers;
    this.addresses = contactDetail.addresses;
    this.secondaryOwners = contactDetail.secondaryOwners;
    this.contactLists = contactDetail.contactLists;
    this.socialMedias = contactDetail.socialMedias;
    this.groupId = contactDetail.groupId;
  }

}
