import {ContactTypeEnum} from "../enums/contact-type-enum";
import {ContactTypeInfoEnum} from "../enums/contact-type-info.enum";
import {ContactIndustry} from "../enums/contact-industry.enum";
import {GDPRStatusEnum} from "../enums/gdpr/gdpr-state.enum";
import {CompanyLegalFormEnum} from "../enums/company-legal-form.enum";
import {GDPRConsentEnum} from "../enums/gdpr/gdpr-consent.enum";
import {MeetingCategoryEnum} from "../enums/meeting/meeting-category.enum";
import {MeetingTypeEnum} from "../enums/meeting/meeting-type.enum";
import {EventTypeEnum} from "../enums/meeting/event-type.enum";
import {AttendanceStatusEnum} from "../enums/meeting/attendance-status.enum";
import {SectorIndustryEnum} from "../enums/sector-industry.enum";
import {ContactInfluenceDegree} from "../enums/contact-influence-degree.enum";
import {ContactCommentTypeEnum} from "../enums/contact-comment-type.enum";

export function transformContactType(value: ContactTypeEnum): string {
  switch (value) {
    case ContactTypeEnum.CLIENT:
      return "Client";
    case ContactTypeEnum.NON_CLIENT:
      return "Non client";
    default:
      return value; // Handle cases where the value doesn't match
  }
}

export function transformContactTypeInfo(value: string): string {
  switch (value) {
    case ContactTypeInfoEnum.ACTIVE_CLIENT:
      return "Active client"
    case ContactTypeInfoEnum.DORMANT_CLIENT:
      return "Dormant client"
    case ContactTypeInfoEnum.ALUMNI:
      return "Alumni"
    case ContactTypeInfoEnum.ATOZ:
      return "ATOZ"
    case ContactTypeInfoEnum.CIVIL_ASSOCIATION:
      return "Civil Association"
    case ContactTypeInfoEnum.COMPETITORS_TO_ATA:
      return "Competitors to ATA (Big 4, Arendt Services, Luxembourg law firms with tax practice, etc)"
    case ContactTypeInfoEnum.FRIENDS_OF_ATOZ:
      return "Friends of ATOZ"
    case ContactTypeInfoEnum.INDEPENDENT_FREELANCE:
      return "Independent/Freelance"
    case ContactTypeInfoEnum.LAW_FIRM:
      return "Law Firm (Abroad or Luxembourg with no tax practice)"
    case ContactTypeInfoEnum.LOCAL_INFLUENCER:
      return "Local influencer"
    case ContactTypeInfoEnum.MARKETING_CONTACT:
      return "Marketing contact"
    case ContactTypeInfoEnum.OFFICIALS:
      return "Officials"
    case ContactTypeInfoEnum.POTENTIAL_CLIENT:
      return "Potential client"
    case ContactTypeInfoEnum.PROFESSIONAL_ASSOCIATION:
      return "Professional associations (Memberships, etc)"
    case ContactTypeInfoEnum.SERVICE_PROVIDERS:
      return "Service providers (Domiciliation, third party AIFMs, banks, custodians, etc)"
    case ContactTypeInfoEnum.TAXAND:
      return "Taxand"
    default:
      return value;
  }
}

export function transformContactIndustry(value: string): string {
  switch (value) {
    case ContactIndustry.PRIVATE_EQUITY:
      return "Private equity";
    case ContactIndustry.REAL_ESTATE:
      return "Real estate";
    case ContactIndustry.VENTURE_CAPITAL:
      return "Venture capital";
    case ContactIndustry.DEBT:
      return "Debt";
    case ContactIndustry.INFRASTRUCTURE:
      return "Infrastructure";
    case ContactIndustry.MULTINATIONALS:
      return "Multinationals";
    case ContactIndustry.FAMILY_OFFICE_HNWI:
      return "Family office / HNWI";
    case ContactIndustry.BANKS_FINANCIAL_INSTITUTIONS:
      return "Banks & financial institutions";
    case ContactIndustry.WHT_PROJECT:
      return "WHT project";
    case ContactIndustry.AVIATION_FINANCE:
      return "Aviation finance";
    case ContactIndustry.OTHERS:
      return "Others";
    default:
      return value;
  }
}

export function transformGDPRStatus(value: GDPRStatusEnum): string {
  switch (value) {
    case GDPRStatusEnum.GDPR_LINK_CLICKED:
      return "Link clicked";
    case GDPRStatusEnum.GDPR_EMAIL_SENT:
      return "Email sent";
    case GDPRStatusEnum.GDPR_COMPLETED:
      return "Completed";
    default:
      return value;
  }
}

export function transformGDPRConsents(value: GDPRConsentEnum): string {
  switch (value) {
    case GDPRConsentEnum.DOT_DIGITAL:
      return "DotDigital";
    case GDPRConsentEnum.NEWS:
      return "News";
    case GDPRConsentEnum.EVENTS:
      return "Events";
    default:
      return value;
  }
}

export function transformInfluenceDegree(value: ContactInfluenceDegree): string {
  switch (value) {
    case ContactInfluenceDegree.INFLUENCER:
      return "Influencer";
    case ContactInfluenceDegree.USER:
      return "User";
    case ContactInfluenceDegree.DECIDER:
      return "Decider";
    case ContactInfluenceDegree.BUYER:
      return "Buyer";
    default:
      return value;
  }
}

export function transformCompanyLegalForm(value: CompanyLegalFormEnum): string {
  switch (value) {
    case CompanyLegalFormEnum.ADMINISTRATION_GOVERNMENT:
      return "Administration/Government";
    case CompanyLegalFormEnum.AG_AND_CO_KGAA:
      return "AG & Co. KGaA";
    case CompanyLegalFormEnum.AG:
      return "AG (Aktiengesellschaft)";
    case CompanyLegalFormEnum.ASBL:
      return "ASBL (Association sans but lucratif)";
    case CompanyLegalFormEnum.BVBA:
      return "BVBA";
    case CompanyLegalFormEnum.CORP:
      return "Corp (Corporation)";
    case CompanyLegalFormEnum.GIE:
      return "GIE (groupement d'intérêt économique)";
    case CompanyLegalFormEnum.GMBH_AND_CO_KG:
      return "GmbH & Co. KG";
    case CompanyLegalFormEnum.GMBH:
      return "GmbH (Gesellschaft mit beschränkter Haftung)";
    case CompanyLegalFormEnum.HNWI:
      return "HNWI";
    case CompanyLegalFormEnum.INC:
      return "Inc. (Incorporated)";
    case CompanyLegalFormEnum.KG:
      return "KG (Kommanditgesellschaft)";
    case CompanyLegalFormEnum.LLC:
      return "LLC (Limited Liability Company)";
    case CompanyLegalFormEnum.LLP:
      return "LLP (Limited Liability Partnership)";
    case CompanyLegalFormEnum.LP:
      return "LLP (Limited Partnership)";
    case CompanyLegalFormEnum.LTD:
      return "Ltd. (Limited)";
    case CompanyLegalFormEnum.NV:
      return "NV (naamloze vennootschap)";
    case CompanyLegalFormEnum.PLC:
      return "PLC (Public Limited Company)";
    case CompanyLegalFormEnum.PLLC:
      return "PLLC (Professional Limited Liability Companv)";
    default:
      return value;
  }
}

export function transformMeetingCategory(value: MeetingCategoryEnum): string {
  switch (value) {
    case MeetingCategoryEnum.MEETING:
      return "Meeting";
    case MeetingCategoryEnum.EVENT:
      return "Event";
    default:
      return value;
  }
}

export function transformMeetingEventType(value: MeetingTypeEnum | EventTypeEnum): string {
  switch (value) {
    case MeetingTypeEnum.MEETING:
      return "Meeting";
    case MeetingTypeEnum.LUNCH_DINNER_DRINKS:
      return "Lunch/Dinner/Drinks";
    case MeetingTypeEnum.EXTERNAL_CONFERENCE:
      return "External Conference";
    case EventTypeEnum.BRIEFING:
      return "Briefing";
    case EventTypeEnum.ROUNDTABLE:
      return "Roundtable";
    case EventTypeEnum.TRAINING:
      return "Training";
    case EventTypeEnum.SOCIAL_EVENT:
      return "Social Event";
    case EventTypeEnum.WORKSHOP:
      return "Workshop";
    default:
      return value;
  }
}

export function transformMeetingAttendanceStatus(value: AttendanceStatusEnum): string {
  switch (value) {
    case AttendanceStatusEnum.ATTENDED:
      return "Attended";
    case AttendanceStatusEnum.EXCUSED:
      return "Excused";
    default:
      return value;
  }
}

export function transformSectorIndustries(values: SectorIndustryEnum | SectorIndustryEnum[]): string {
  function mapIndustry(value: SectorIndustryEnum): string {
    switch (value) {
      case SectorIndustryEnum.ASSOCIATION:
        return "Association";
      case SectorIndustryEnum.ATOZ:
        return "ATOZ";
      case SectorIndustryEnum.AVIATION:
        return "Aviation";
      case SectorIndustryEnum.BANKING_CAPITAL_MARKETS:
        return "Banking Capital Markets";
      case SectorIndustryEnum.BIG_4:
        return "Big 4";
      case SectorIndustryEnum.BUSINESS_PROFESSIONAL_SERVICES:
        return "Business Professional Services";
      case SectorIndustryEnum.COMPETITORS:
        return "Competitors";
      case SectorIndustryEnum.CONSTRUCTION:
        return "Construction";
      case SectorIndustryEnum.CONSUMER_GOODS_SERVICES:
        return "Consumer Goods Services";
      case SectorIndustryEnum.INFRASTRUCTURE:
        return "Infrastructure";
      case SectorIndustryEnum.ENERGY_OIL_GAS:
        return "Energy Oil Gas";
      case SectorIndustryEnum.EDUCATION:
        return "Education";
      case SectorIndustryEnum.FAMILY_OFFICES_WEALTH_MANAGEMENT:
        return "Family Offices Wealth Management";
      case SectorIndustryEnum.FINANCIAL_SERVICES:
        return "Financial Services";
      case SectorIndustryEnum.GOVERNMENT_PUBLIC_SECTOR:
        return "Government Public Sector";
      case SectorIndustryEnum.HEALTHCARE_LIFE_SCIENCES:
        return "Healthcare Life Sciences";
      case SectorIndustryEnum.HNWI:
        return "HNWIs";
      case SectorIndustryEnum.INSURANCE:
        return "Insurance";
      case SectorIndustryEnum.INVESTMENT_MANAGEMENT:
        return "Investment Management";
      case SectorIndustryEnum.LAW_FIRMS_COMPETITORS:
        return "Law Firms Competitors";
      case SectorIndustryEnum.LAW_FIRMS_LEGAL_SERVICES:
        return "Law Firms Legal Services";
      case SectorIndustryEnum.MANUFACTURING_ENGINEERING_CHEMICALS:
        return "Manufacturing Engineering Chemicals";
      case SectorIndustryEnum.MARKETING_ADVERTISING_SALES:
        return "Marketing Advertising Sales";
      case SectorIndustryEnum.OTHER_SERVICES:
        return "Other Services";
      case SectorIndustryEnum.PRIVATE_EQUITY:
        return "Private Equity";
      case SectorIndustryEnum.REAL_ESTATE:
        return "Real Estate";
      case SectorIndustryEnum.SERVICE_PROVIDERS:
        return "Service Providers";
      case SectorIndustryEnum.SOVEREIGN_WEALTH:
        return "Sovereign Wealth";
      case SectorIndustryEnum.SPORTS_ENTERTAINMENT:
        return "Sports Entertainment";
      case SectorIndustryEnum.TAXAND:
        return "Taxand";
      case SectorIndustryEnum.TECHNOLOGY_MEDIA_TELECOMMUNICATIONS:
        return "Technology Media Telecommunications";
      case SectorIndustryEnum.TRANSPORT_LOGISTICS:
        return "Transport Logistics";
      case SectorIndustryEnum.TRUST_COMPANY:
        return "Trust Company";
      case SectorIndustryEnum.VENTURE_CAPITAL:
        return "Venture Capital";
      case SectorIndustryEnum.WHT:
        return "WHT";
      default:
        return value;
    }
  }

  if (!values) {
    return '-';
  }

  // Handle single value or array
  if (Array.isArray(values)) {
    return values
      .map(value => mapIndustry(value))
      .join(', ');
  } else {
    return mapIndustry(values);
  }
}

export function transformContactCommentType(value: ContactCommentTypeEnum): string {
  switch (value) {
    case ContactCommentTypeEnum.COMMENT:
      return 'Information';
    case ContactCommentTypeEnum.REQUEST_CHANGES:
      return 'Request changes';
    default:
      return value;
  }
}
