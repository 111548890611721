<div class="h-[calc(100vh-2rem)] flex flex-col bg-neutral-0">
  <div class="flex justify-between items-center bg-neutral-0 border-b-[1px] border-b-neutral-300 py-7 px-6">
    <div class="flex flex-col gap-2">
      <p class="text-xl text-neutral-900 font-medium">Learn more about CRM-Atoz</p>
      <p class="text-base text-neutral-700">Comprehensive resources to help you maximize the platform’s potential.</p>
    </div>
  </div>
  <div class="h-full w-full">
    <router-outlet/>
  </div>
</div>
