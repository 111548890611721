import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map, Observable} from "rxjs";
import {PostGdprResponseModel} from "../../models/gdpr/post-gdpr-response.model";
import {GdprResponseModel} from "../../models/gdpr/gdpr-response.model";
import {PutGdprConsentInterface} from "../../interfaces/gdpr/put-gdpr-consent.interface";
import {GdprRequestDetailsModel} from "../../models/gdpr/gdpr-request-details.model";
import {GdprContactModel} from "../../modals/gdpr/gdpr-contact.model";
import moment from 'moment';

const GDPR_PUBIC_PATH_SEGMENT = 'public/gdpr';
const CONTACT_GDPR_PATH_SEGMENT = 'contacts/$contactId/gdpr';

@Injectable()
export class GdprService {

  constructor(private readonly http: HttpClient) {
  }

  verifyGDPRRequestToken = (gdprToken: string): Observable<boolean> => {
    return this.http.get<boolean>(`${environment.apiUrl}${GDPR_PUBIC_PATH_SEGMENT}/verify?gdpr_token=${gdprToken}`)
  }

  submitGDPRPublicForm = (gdprResponsePayload: PostGdprResponseModel) => {
    return this.http.post(
      `${environment.apiUrl}${GDPR_PUBIC_PATH_SEGMENT}/submit`,
      gdprResponsePayload
    )
  }

  updateGDPRConsent = (contactId: number, putGdprConsent: PutGdprConsentInterface) => {
    return this.http.put<void>(
      `${environment.apiUrl}${CONTACT_GDPR_PATH_SEGMENT
        .replace('$contactId', String(contactId))}/consent`,
      putGdprConsent
    )
  }

  getContactGDPRRequests = (contactId: number) => {
    return this.http.get<GdprRequestDetailsModel[]>(
      `${environment.apiUrl}${CONTACT_GDPR_PATH_SEGMENT
        .replace('$contactId', String(contactId))}/requests`
    ).pipe(
      map(
        (gdprResponses: GdprRequestDetailsModel[]) => gdprResponses.map(
          (gdprRequest: GdprRequestDetailsModel) => {
            let gdprRequestOb: GdprRequestDetailsModel = new GdprRequestDetailsModel(
              gdprRequest.id,
              moment.utc(gdprRequest.createdAt).local(),
              moment.utc(gdprRequest.expirationDate).local(),
              null,
              gdprRequest.cancellationDate ? moment.utc(gdprRequest.cancellationDate).local() : null
            );

            if (gdprRequest.gdprResponse) {
              gdprRequestOb.gdprResponse = new GdprResponseModel(
                gdprRequest.gdprResponse.title,
                gdprRequest.gdprResponse.firstName,
                gdprRequest.gdprResponse.lastName,
                gdprRequest.gdprResponse.jobTitle,
                gdprRequest.gdprResponse.companyName,
                gdprRequest.gdprResponse.newsConsent,
                gdprRequest.gdprResponse.eventsConsent,
                gdprRequest.gdprResponse.dotDigitalConsent,
                moment.utc(gdprRequest.gdprResponse.modifiedAt).local()
              )
            }
            return gdprRequestOb;
          }
        )
      )
    )
  }

  /**
   * Retrieves contact information using the GDPR token
   * @param token the GDPR token
   * @returns Observable<GDPRContactModel>
   */
  getContactByToken(token: string): Observable<GdprContactModel> {
    return this.http.get<GdprContactModel>(`${environment.apiUrl}${GDPR_PUBIC_PATH_SEGMENT}/contact/${token}`)
      .pipe(map(res => new GdprContactModel(
        res.id, res.title, res.firstName, res.lastName, res.jobTitle, res.companyName
      )));
  }

  /**
   * Cancels the specified GDPR request for the given contact and creates a new request
   * using the data from the canceled one.
   * @param contactId - The ID of the contact
   * @param gdprRequestId - The ID of the GDPR request to be canceled
   */
  resendGDPRRequest(contactId: number, gdprRequestId: number) :Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}${CONTACT_GDPR_PATH_SEGMENT
        .replace('$contactId', String(contactId))}/requests/${gdprRequestId}/resend`,{});
  }

  /**
   * Cancels the specified GDPR request related to the specified contact.
   * @param contactId - The ID of the contact
   * @param gdprRequestId - The ID of the GDPR request to be canceled
   */
  cancelGDPRRequest(contactId: number, gdprRequestId: number) :Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}${CONTACT_GDPR_PATH_SEGMENT
        .replace('$contactId', String(contactId))}/requests/${gdprRequestId}/cancel`,{});
  }
}
