import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomAvatarColorSchemeService {

  // Array of color names. These colors form the base for generating avatar color schemes.
  colors = [
    'green', 'red', 'violet', 'orange',
    'grass', 'ruby', 'gold', 'flame', 'rose',
    'sky', 'magenta', 'lime', 'brown'
  ];

  // Generate color schemes by mapping over the colors array.
  // Each color scheme contains:
  // 1. A background class string (`bg-{color}-100`).
  // 2. A text color class string (`text-{color}-800`).
  colorSchemes = this.colors.map(color => {
    return {
      bg: `bg-${color}-100`, // CSS class for background color with light intensity.
      color: `text-${color}-800`, // CSS class for text color with high intensity.
    };
  });


  /**
   * Method to generate a consistent avatar class based on a user's name.
   * @param  userName - The user's name for which the avatar class is generated.
   * @returns - A string containing the background and text color class names.
   */
  getAvatarClassByUserName(userName: string): string {
    // Convert the username into a numeric value.
    const userNameAsNumber = userName.split('').reduce((result, char, index) => {
      return result + char.charCodeAt(0) * (index + 1);
    }, 0);

    // Get a random color scheme.
    const randomColorScheme = this.colorSchemes[userNameAsNumber % this.colors.length];

    // Return a string containing both background and text color classes.
    return randomColorScheme.bg + ' ' + randomColorScheme.color;
  }
}
